import { clear as clearCache, get as getCache, set as setCache } from "@/utils/cartCache";
import { remove as lsRemove, set as lsSet } from "local-storage";
import { models, request } from "leatherman-js";
import { analyticsPurchaseComplete } from "@/utils/checkout";
import router from "@/router";
import Status from "@/classes/Status";
import { NAMES as SURVEY_NAMES } from "@/utils/surveys";

let replacementPackages = {};
if (process.env.VUE_APP_REPLACEMENT_PACKAGES) {
  process.env.VUE_APP_REPLACEMENT_PACKAGES.split(",").forEach((pair) => {
    const [key, value] = pair.split(":");
    replacementPackages[key] = Number(value);
  });
}

let replacementLocations = [];
if (process.env.VUE_APP_REPLACEMENT_LOCATIONS) {
  replacementLocations = process.env.VUE_APP_REPLACEMENT_LOCATIONS.split(",");
}

const surveyNicknamesMap = {
  STOREBUILDER: SURVEY_NAMES.STOREBUILDER,
  MEMBERSHIP: "WPQuickStart Membership",
};

const queryParams = [
  "packageId",
  "appId",
  "term",
  "locationId",
  "couponCode",
  "installApp",
  "email",
];
const setQueryParam = (key, value) => {
  const isCheckout = router.currentRoute.value.meta.cart;
  if (isCheckout) {
    const query = { ...router.currentRoute.value.query };
    if (value !== null && value !== undefined) {
      query[key] = value;
    } else {
      delete query[key];
    }
    router.replace({ query });
  }
};
const updateQueryString = (item) => {
  if (item.orderType !== "virt-guest-cloud") {
    return;
  }
  const isCheckout = router.currentRoute.value.meta.cart;
  if (isCheckout) {
    const query = { ...router.currentRoute.value.query };
    Object.entries(item).forEach(([key, value]) => {
      if (queryParams.includes(key)) {
        if (value !== null && value !== undefined) {
          query[key] = value;
        } else {
          delete query[key];
        }
      }
    });
    router.replace({ query });
  }
};

const cart = {
  namespaced: true,
  state: {
    items: getCache(),
    purchaseStatus: new Status(),
    estimate: {
      items: [],
    },
    discount_errors: [],
    estimateStatus: new Status(),
    discountStatus: new Status(),
    discounts: [],
    cartstack: true,
    email: null,
  },
  mutations: {
    ADD_ITEM(state, payload) {
      state.items.push(payload);
      setCache(state.items);
    },
    UPDATE_ITEM(state, payload) {
      const index = state.items.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        Object.keys(state.items[index]).forEach((key) => {
          if (payload[key] !== undefined) {
            state.items[index][key] = payload[key];
          }
        });
      }
      setCache(state.items);
    },
    REMOVE_ITEM(state, payload) {
      state.items = state.items.filter((item) => item.id !== payload.id);
      setCache(state.items);
    },
    RESET(state) {
      state.items = [];
      clearCache();
      lsRemove("discount-code-1");
      lsRemove("discount-code-12");
      lsRemove("discount-code-24");
      lsRemove("discount-code-36");
    },
    SET_PURCHASE_STATUS(state, payload) {
      state.purchaseStatus.value = payload;
    },
    ADD_ADDON(state, payload) {
      const index = state.items.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        state.items[index].addAddon(payload.addonId);
      }
      setCache(state.items);
    },
    REMOVE_ADDON(state, payload) {
      const index = state.items.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        state.items[index].removeAddon(payload.addonId);
      }
      setCache(state.items);
    },
    ADD_DISCOUNT(state, payload) {
      state.discounts.push(payload);
    },
    REMOVE_DISCOUNT(state, payload) {
      state.discounts = state.discounts.filter((discount) => discount !== payload);
    },
    CLEAR_DISCOUNTS(state) {
      state.discounts = [];
      state.items.forEach((item) => (item.discounts = []));
    },
    CLEAR_ESTIMATE(state) {
      state.estimate.items = [];
    },
    SET_ESTIMATE(state, payload) {
      state.estimate = payload;
    },
    SET_ESTIMATE_STATUS(state, payload) {
      state.estimateStatus.value = payload;
    },
    SET_DISCOUNT_STATUS(state, payload) {
      state.discountStatus.value = payload;
    },
    SET_EMAIL(state, payload) {
      state.email = payload;
    },
    SET_CARTSTACK(state, payload) {
      state.cartstack = payload;
    },
    SET_DISCOUNT_ERRORS(state, payload) {
      state.discount_errors = payload;
    },
  },
  actions: {
    addItem({ commit }, payload) {
      commit("ADD_ITEM", payload);
    },
    updateItem({ commit }, item) {
      updateQueryString(item);
      commit("UPDATE_ITEM", item);
    },
    removeItem({ commit }, item) {
      commit("REMOVE_ITEM", item);
    },
    addAddon({ commit }, { id, addonId }) {
      commit("ADD_ADDON", { id, addonId });
    },
    removeAddon({ commit }, { id, addonId }) {
      commit("REMOVE_ADDON", { id, addonId });
    },
    addDiscount({ commit }, { discountCode, term }) {
      setQueryParam("couponCode", discountCode);

      // clear term based discounts
      lsRemove("discount-code-1");
      lsRemove("discount-code-12");
      lsRemove("discount-code-24");
      lsRemove("discount-code-36");

      // store in local storage
      lsSet(`discount-code-${term}`, discountCode);

      // save discount in state
      commit("ADD_DISCOUNT", discountCode);
    },
    clearDiscounts({ commit }, data) {
      setQueryParam("couponCode", null);

      if (typeof data?.clearCache === "undefined" || data?.clearCache === true) {
        lsRemove("discount-code-1");
        lsRemove("discount-code-12");
        lsRemove("discount-code-24");
        lsRemove("discount-code-36");
      }
      commit("CLEAR_DISCOUNTS");
    },
    clearEstimate({ commit }) {
      commit("CLEAR_ESTIMATE");
      commit("SET_ESTIMATE_STATUS", Status.UNINITIALIZED);
    },
    reset({ commit, dispatch }) {
      commit("CLEAR_DISCOUNTS");
      commit("RESET");
      commit("SET_PURCHASE_STATUS", Status.UNINITIALIZED);

      // reset survey stuff too
      dispatch("surveys/reset", null, { root: true });
    },
    async estimate({ state, commit, dispatch }) {
      commit("SET_ESTIMATE_STATUS", Status.LOADING);
      commit("SET_DISCOUNT_STATUS", Status.UNINITIALIZED);
      try {
        const response = await request.makeParse(
          "estimateOrder",
          {},
          {
            items: state.items,
            discounts: state.discounts,
          }
        );
        dispatch("handleEstimateMessages", response.messages || []);
        commit("SET_ESTIMATE", response);
        commit("SET_ESTIMATE_STATUS", Status.LOADED);
        if (parseFloat(response.total_discount) > 0) {
          commit("SET_DISCOUNT_STATUS", Status.LOADED);
        }
        return response;
      } catch (error) {
        console.log(error);
        commit("SET_ESTIMATE_STATUS", Status.ERROR);
      }
    },
    handleEstimateMessages({ commit }, messages) {
      // clear discount errors
      commit("SET_DISCOUNT_ERRORS", []);
      messages.forEach((message) => {
        switch (message.scope) {
          case "discount":
            if (message.status == "failure") {
              commit("SET_DISCOUNT_ERRORS", [message.message]);
              commit("REMOVE_DISCOUNT", message.code);
              commit("SET_DISCOUNT_STATUS", Status.ERROR);
            }
            break;
          default:
            commit("SET_DISCOUNT_STATUS", Status.UNINITIALIZED);
            break;
        }
      });
    },
    async checkout({ commit, state, dispatch }) {
      commit("SET_PURCHASE_STATUS", Status.LOADING);
      try {
        const response = await Promise.all(
          state.items.map((item) => {
            switch (item.orderType) {
              case "virt-guest-cloud":
                return dispatch("checkoutPlan", item);
              case "domain":
                return dispatch("checkoutDomain", item);
            }
          })
        );
        commit("SET_PURCHASE_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_PURCHASE_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    async checkoutPlan({ state, rootGetters }, item) {
      try {
        // get currently selected app
        const location = rootGetters["locations/getLocationById"](item.locationId);

        let packageId = item.packageId;

        // certain packages are substituteded for others when certain locations are selected MAD-5754
        if (replacementLocations.includes(location.code) && replacementPackages[item.packageId]) {
          packageId = replacementPackages[item.packageId];
          console.log(
            `Replacing package id ${item.packageId} with ${packageId} (only for location ${location.code}) `
          );
        }

        let nicknameOverride = item.nickname;
        if (rootGetters["surveys/requireSurvey"]) {
          nicknameOverride = surveyNicknamesMap[rootGetters["surveys/getSurveyType"]];
        }

        const referral = rootGetters["affiliates/getReferral"];
        const data = {
          appId: item.appId,
          packageId,
          installApp: item.installApp,
          locationId: item.locationId,
          nickname: nicknameOverride,
          affiliate: item.affiliateId,
          addonIds: item.addons.length ? item.addons.map(({ id }) => id) : null,
          discountCode: item.discounts[0] || null,
          term: item.term,
          context: referral || [],
        };

        const response = await request.makeParse("createPlan", {}, data);
        // orderId, revenue, coupon, discount
        const pack = rootGetters["packages/site/getItem"];
        const revenue = pack.pricing["1"] + pack.appPricing[item.appId];

        analyticsPurchaseComplete(
          response.order?.id,
          revenue,
          data.discountCode,
          state.estimate.total_discount
        );

        return response;
      } catch (error) {
        console.log("PLAN PURCHASE ERROR:", error);
        return Promise.reject(error);
      }
    },
    async checkoutDomain({ rootGetters, rootState }, item) {
      try {
        const billingAddress = rootGetters["addresses/getBillingAddress"];
        const contact = item.contact || { addressId: billingAddress.id };

        const data = {
          domainName: item.domainName,
          packageId: item.packageId,
          tldId: item.tld.id,
          addonIds: item.addons.length ? item.addons.map(({ id }) => id) : null,
          discountCode: item.discounts[0] || null,
          term: 12,
          UIFlowUUID: item.UIFlowUUID,
          contact,
        };
        const response = await request.makeParse("registerDomain", {}, data);

        const cart = rootState.cart;
        const revenue = cart.estimate.total;
        const discount = cart.estimate.total_discount;

        analyticsPurchaseComplete(
          `${item.domainName}.${item.tld.identity}`,
          revenue,
          data.discountCode,
          discount
        );

        return response;
      } catch (error) {
        console.log("DOMAIN PURCHASE ERROR:", error);
        return Promise.reject(
          `There was an error registering ${item.domainName}.${item.tld.identity}. Please contact customer support.`
        );
      }
    },
    async legacyGetTax(context, data) {
      try {
        const addons = data.addons || [];
        const discounts = data.discounts || [];
        const salesTax = await request.makeParse(
          "getSalesTax",
          {},
          {
            appId: data.appId,
            packageId: data.packageId,
            term: data.term,
            addonIds: addons.map((addon) => addon.id),
            discountCode: discounts[0],
            planId: data.planId,
          }
        );
        return salesTax;
      } catch (e) {
        console.log("LEGACY TAX ERROR:", e);
      }
    },
    setClassification({ commit }, payload) {
      commit("SET_CLASSIFICATION", payload);
    },
    setEmail({ commit }, payload) {
      setQueryParam("email", payload);
      commit("SET_EMAIL", payload);
    },
    cartstackActive({ commit }, active) {
      commit("SET_CARTSTACK", active);
    },
    updateAddress({ commit }, fields) {
      const address = new models.Address();
      Object.keys(fields).forEach((key) => (address[key] = fields[key]));
      commit("SET_ADDRESS", address);
    },
    clearAddress({ commit }) {
      commit("SET_ADDRESS", new models.Address());
    },
    legacyCalculateTax(context, data) {
      return request.makeParse(
        "getSalesTax",
        {},
        {
          appId: data.appId,
          packageId: data.packageId,
          term: data.term,
          discountCode: data.discountCode,
          planId: data.planId,
        }
      );
    },
  },
  getters: {
    getDomainItems: (state) => state.items.filter((item) => item.orderType === "domain"),
    getPlanItems: (state) => state.items.filter((item) => item.orderType === "virt-guest-cloud"),
    getPlanEstimates: (state) =>
      state.estimate.items.filter((item) => item.package.type === "virt-guest-cloud"),
    getDomainEstimates: (state) =>
      state.estimate.items.filter((item) => item.package.type === "domain"),
    canUpgrade: (state, getters, rootState, rootGetters) => {
      const hasBillingAddress = !!rootGetters["addresses/getBillingAddress"];
      const hasPrimaryPaymentMethod = !!rootGetters["paymentMethods/getPrimary"];
      return hasBillingAddress && hasPrimaryPaymentMethod;
    },
  },
};

export default cart;
